exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-de-tsx": () => import("./../../../src/pages/index.de.tsx" /* webpackChunkName: "component---src-pages-index-de-tsx" */),
  "component---src-pages-index-hu-tsx": () => import("./../../../src/pages/index.hu.tsx" /* webpackChunkName: "component---src-pages-index-hu-tsx" */),
  "component---src-pages-kontakt-de-tsx": () => import("./../../../src/pages/kontakt.de.tsx" /* webpackChunkName: "component---src-pages-kontakt-de-tsx" */),
  "component---src-pages-kontakt-hu-tsx": () => import("./../../../src/pages/kontakt.hu.tsx" /* webpackChunkName: "component---src-pages-kontakt-hu-tsx" */),
  "component---src-pages-leistungen-de-tsx": () => import("./../../../src/pages/leistungen.de.tsx" /* webpackChunkName: "component---src-pages-leistungen-de-tsx" */),
  "component---src-pages-leistungen-hu-tsx": () => import("./../../../src/pages/leistungen.hu.tsx" /* webpackChunkName: "component---src-pages-leistungen-hu-tsx" */),
  "component---src-pages-ueber-mich-de-tsx": () => import("./../../../src/pages/ueber-mich.de.tsx" /* webpackChunkName: "component---src-pages-ueber-mich-de-tsx" */),
  "component---src-pages-ueber-mich-hu-tsx": () => import("./../../../src/pages/ueber-mich.hu.tsx" /* webpackChunkName: "component---src-pages-ueber-mich-hu-tsx" */)
}

